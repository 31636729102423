
import Vue from 'vue'
import router from './router'
import store from './store/index'
import { pageController } from '@/config/config'
console.log(location.host)
store.commit('SET_WEBNAME', location.host)
let skin = window.skin || 1
import(`@/assets/css/${skin}.css`)
Vue.prototype.$pageController = Object.assign({}, pageController['default'], pageController[skin]) 
Vue.prototype.$nav =  window.nav
Vue.prototype.$main =  window.main
router.beforeEach(async (to, from, next) => {
  next()
})
router.afterEach((to) => {
  store.commit('SET_NAV', to.name)
})